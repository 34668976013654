import React from "react"

import SEO from "../components/SEO"
import LandingPageLayout from "../components/Layouts/LandingPageLayout"
import ProjectsInProgress from "../components/ProjectsInProgress"

const title = "Make your marketing more efficient"
const introCopy = [
  "From building emails, analysing multivariate tests, and figuring out the ROI for onboarding a new tool or technology... you've got a lot on your plate.",
  "We build tools to help you get things done - some are even fancy freebies."
]
const description = "Digital marketing opens your business up to a whole world of opportunities. Creative Ilk creates free and premium marketing tools for email marketing and more. Get creative."


const IndexPage = () => {
  return (
    <LandingPageLayout title={title} introCopy={introCopy}>
      <SEO title="Home" description={description} />
      <section>
        <h4 className="text-left py-3">
          Here are a few things we've worked on.
        </h4>
        <ProjectsInProgress />
      </section>
    </LandingPageLayout>
  )
}

export default IndexPage

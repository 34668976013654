import React from "react"
import Navbar from "./Navbar"
import WordCycler from "../WordCycler"
import Footer from "./Footer"

interface LandingPageLayoutProps {
  title: string
  introCopy: string[]
}

const LandingPageLayout: React.FunctionComponent<LandingPageLayoutProps> = ({ title, introCopy, children }) => {
  return (
    <React.Fragment>
      <Navbar />
      <header className="bg-primary text-white">
        <div className="container py-5">
          <h1>
            {title}
          </h1>
          {introCopy.map((line, index) => (
            <p className="lead" key={index}>
              {line}
            </p>
          ))}
        </div>
      </header>
      <aside className="bg-secondary text-white">
        <div className="container py-5">
          <h2>
            Creative Ilk is for <WordCycler words={["marketers", "small business owners", "side hustlers", "task jugglers", "too-many-hats-to-namers", "you"]} interval={1500} />
          </h2>
        </div>
      </aside>
      <main className="container mt-3 flex-grow-1">
        {children}
      </main>
      <Footer />
    </React.Fragment>
  )
}

export default LandingPageLayout

import React from "react";

interface Props {
  words: string[];
  interval: number
}

const WordCycler: React.FunctionComponent<Props> = ({ words, interval }) => {
  const [count, setCount] = React.useState(0)
  const word = React.useMemo(
    () => words[count % words.length],
    [words, count]
  )

  React.useEffect(() => {
    const handle = window.setTimeout(() => setCount(count + 1), interval)
    return () => window.clearTimeout(handle)
  }, [count, interval])

  return <React.Fragment>{word}</React.Fragment>
}

export default WordCycler